import React from "react";
import theme from "theme";
import { Theme, Span, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				FitZone – Bugün modern spor salonuna katılın
			</title>
			<meta name={"description"} content={"FitZone'u keşfedin, fitness için ideal destinasyonunuz."} />
			<meta property={"og:title"} content={"FitZone – Bugün modern spor salonuna katılın"} />
			<meta property={"og:description"} content={"FitZone'u keşfedin, fitness için ideal destinasyonunuz."} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://varuntarest.com/img/43625757.png"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Span
						color="--orange"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						FitZone{" "}
					</Span>
					spor salonu
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					FitZone'u keşfedin, fitness için ideal destinasyonunuz. Modern spor salonumuz, birinci sınıf ekipmanlar, kişiselleştirilmiş antrenmanlar ve destekleyici bir topluluk sunar. Fitness yolculuğunuza bugün bizimle başlayın!
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-orange"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Katılın
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://varuntarest.com/img/1.jpg) center center/cover no-repeat scroll padding-box"
				lg-min-height="400px"
			/>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-8">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
				width="100%"
				min-width="100%"
			/>
			<Box
				display="flex"
				padding="0px 0px 0px 30px"
				margin="0px -10px 0px 0px"
				lg-margin="0px 10px 0px 10px"
				lg-padding="0px 0px 0px 0"
				height="100%"
				min-width="100px"
				background="rgba(0, 0, 0, 0) url(https://varuntarest.com/img/2.jpg) 30% 0%/cover repeat scroll padding-box"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 60px 0px 60px"
				lg-margin="0px 0px 50px 0px"
				sm-padding="0px 25px 0px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Fitness hedeflerinize bizimle ulaşın
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						{" "}FitZone'da, yüksek kaliteli ekipmanlara erişimin fitness hedeflerinize ulaşmanız için kritik öneme sahip olduğuna inanıyoruz. Spor salonumuz, antrenmanlarınızdan en iyi şekilde yararlanmanıza yardımcı olmak için tasarlanmış modern ekipmanlar ve araçların geniş bir yelpazesiyle donatılmıştır.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Modern koşu bantlarından ve eliptik makinelerden işlevsel antrenman istasyonlarına ve serbest ağırlıklara kadar, her kas grubunu çalıştırmak ve her fitness hedefinize ulaşmak için ihtiyacınız olan her şeye sahibiz. Ekipmanlarımız düzenli olarak bakım yapılır ve optimum performans ve güvenliği sağlamak için güncellenir.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Maksimum sonuçlara ulaşmanıza yardımcı olmak için, FitZone kişisel ihtiyaçlarınızı ve hedeflerinizi dikkate alarak tasarlanmış kişiselleştirilmiş antrenman programları sunar. Sertifikalı kişisel antrenörlerimizden oluşan ekibimiz, kilo vermek, kas kütlesi kazanmak veya genel fiziksel formunuzu iyileştirmek isteyip istemediğinize bakılmaksızın uzman rehberliği ve desteği sağlamaya kararlıdır.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Schedule-5

">
			<Override slot="SectionContent" />
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 500 56px/1.2 --fontFamily-serifGeorgia"
				color="--dark"
				sm-margin="0px 0px 30px 0px"
			>
				Grup Fitness Dersleri
			</Text>
			<Text margin="0px 0px 70px 0px" text-align="left" font="--base" color="#6a6e6e">
				Her bireysel antrenmanın yanı sıra, FitZone, çeşitli ilgi alanları ve fitness seviyelerine uygun geniş bir grup fitness dersleri yelpazesi sunar. Derslerimiz, hedeflerinize ulaşmanıza yardımcı olmak için coşkuyla çalışan deneyimli eğitmenler tarafından yönetilir.
				<br />
				<br />
				Enerjik kardiyo antrenmanlarından rahatlatıcı yoga seanslarına kadar herkes için bir şeyler var. Grup dersleri, topluluk dostu bir ortam yaratır ve motive kalmanın ve fitness hedeflerinizi paylaşan yeni insanlarla tanışmanın harika bir yoludur.
			</Text>
		</Section>
		<Components.FoodImages />
		<Components.Testimonials />
		<Components.Footer />
	</Theme>;
});